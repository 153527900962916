@import "_colors";
@import "_mixins";

// ---------- base markup ---------- 
.fx-100 {
    @include set-width(100%);
}

.fx-50 {
    @include set-width(50%);
}

.fx-33 {
    @include set-width(33.333%);
}

.fx-25 {
    @include set-width(25%);
}

.fx-20 {
    @include set-width(20%);
}

.fx-15 {
    @include set-width(15%);
}

.fx-10 {
    @include set-width(10%);
}

.pull-right {
    display: flex;
    margin-left: auto;
}

.row {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: center;
}

.col {
    display: flex;
    flex-direction: column;
}

// ---------- components ---------- 
mat-form-field {
    padding: 7px;
}

.mat-toolbar,
.mat-toolbar h1,
.mat-toolbar h2,
.mat-toolbar h3,
.mat-toolbar h4,
.mat-toolbar h5,
.mat-toolbar h6 {
    font-size: 14px;
    font-weight: bold;
    line-height: 17px;
    font-family: "Gotham", "Helvetica Neue", sans-serif;
    margin: 0;
}

.mat-form-field {
    font-size: 14px;
    font-weight: 500;
    line-height: 1.125;
    font-family: "Gotham", "Helvetica Neue", sans-serif;
}

.mat-button,
.mat-fab,
.mat-flat-button,
.mat-icon-button,
.mat-mini-fab,
.mat-raised-button,
.mat-stroked-button {
    font-family: "Gotham", "Helvetica Neue", sans-serif;
    font-size: 14px;
    font-weight: 600;
    color: $button-color;
}

// ---------- table ---------- 
.mat-header-row {
    text-align: center;
}

.mat-cell:first-of-type,
.mat-footer-cell:first-of-type,
.mat-header-cell:first-of-type {
    padding-left: 3px;
}

.mat-cell:last-of-type,
.mat-footer-cell:last-of-type,
.mat-header-cell:last-of-type {
    padding-right: 3px;
}


.mat-form-field-wrapper {
    //bottom space for error validation message
    padding-bottom: 0;
}

.mat-toolbar-row,
.mat-toolbar-single-row {
    padding: 0 !important;
}

.mat-button-toggle-appearance-standard .mat-button-toggle-label-content {
    line-height: 26px !important;
    padding: 0 18px !important;
    font-weight: 400;
}

.mat-button-toggle-group-appearance-standard,
.mat-button-toggle-standalone.mat-button-toggle-appearance-standard {
    border-radius: 20px !important;
}

.mat-button-toggle-checked {
    background-color: $toogle-color;
    color: white !important;
}

.mat-toolbar {
    background: $selected-row-color;
}

mat-icon {
    color: $button-color;
    vertical-align: middle;
}

.mat-dialog-actions button {
    width: 120px;
}

.mat-button:focus,
button:focus {
    outline: 0;
}

.mat-select-panel {
    margin: 23px 0 0 40px;
}

// ----------  ----------  ----------  ----------  ---------- 
// TODO should be changed to override main material theme
.mat-slide-toggle.mat-checked {
    .mat-slide-toggle-thumb {
        background-color: $toogle-color;
    }

    .mat-slide-toggle-bar,
    .mat-ripple-element {
        background-color: $toogle-lite-color;
    }
}

.mat-checkbox-checked.mat-accent,
.mat-checkbox-indeterminate.mat-accent {
    .mat-checkbox-background {
        background-color: $toogle-color;
    }
}

.mat-table .mat-checkbox label {
    margin-bottom: 3px !important;
}

.mat-menu-content:not(:empty) {
    padding: 5px;
}

// ---------- mat-fab ---------- 
.mat-fab.mat-accent,
.mat-flat-button.mat-accent,
.mat-mini-fab.mat-accent,
.mat-raised-button.mat-accent {
    background-color: $button-color;
    color: white;
}