@import "_colors";
@import "_variables";

/* for sidenav to take a whole page */
html,
body {
    height: 100%;
    padding: 0;
    margin: 0;
}

.page {
    margin-bottom: 15px;
    background-color: #fff;
    padding: 0 40px;
}

.page-with-shadow {
    box-shadow:
        0 5px 5px -3px rgba(0, 0, 0, .2),
        0 8px 10px 1px rgba(0, 0, 0, .14),
        0 3px 14px 2px rgba(0, 0, 0, .12);
    padding: 15px 20px;
    margin: 30px 30px;
    background-color: #fff;
}

/* Set padding to keep content from hitting the edges */
.body-content {
    padding-left: 15px;
    padding-right: 15px;
    background-color: $body-color;
}

.page-content {
    background-color: $page-content-color;
}

.page-toolbar {
    background-color: white !important;
}

.filter-toolbar {
    background-color: white !important;
    cursor: default;
    padding: 0;
    align-items: baseline !important;
}

.project-count {
    font-weight: bold;
    font-size: 18px;
}

.shuffle-icon {
    font-size: 18px;
    font-weight: 400;
    height: auto;
    width: auto;
}

.small-icon {
    font-size: 19px !important;
    padding-top: 3px;
}

.margin-left-md {
    margin-left: 15px
}

//scrollbar
::-webkit-scrollbar {
    width: 5px;
    height: 5px
}

/* Track */
::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px grey;
    border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
    background: $toogle-color;
    border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
    background: $button-color;
}