@import "_colors";
@import "_variables";

.mat-table-container {
    display: flex;
    flex-direction: column;
}

.mat-table {
    overflow: auto;
    height: inherit;
}

.element-row {
    position: relative;
}

.element-row {
    cursor: pointer;
}

.selected-row {
    background: #f0f0f0;
    // font-weight: bold;
}

.mat-cell {

    a,
    p,
    span,
    div {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    button {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: pre-wrap;
    }
}

.element-row:hover,
.element-row.expanded {
    background: #f0f0f0;
    // border-bottom-color: transparent;
}

body a {
    color: $button-color;
    text-decoration: none;
}

.delails-info--expanded {
    width: 100%;
    padding: 0 !important;
    margin: auto;
    flex-wrap: nowrap;
    align-items: start;
    border-left: 1px solid $lightgray;
    border-right: 1px solid $lightgray;
    background: $expanded-row-color;
    height: 380px;
}

.details-info-left-content {
    width: 150px;
    min-width: 200px;
    border-right: 1px solid rgba(0, 0, 0, .12);
    padding: 20px 20px 0 10px;
    height: -webkit-fill-available;

    p {
        font-size: 16px;
        font-weight: bold;
        color: black;
    }

    span {
        font-size: 12px;
        font-weight: 300;
        color: $light-gray-color;
    }

    a {
        font-size: 16px;
        font-weight: bold;
        color: $button-color;
        cursor: pointer;
    }
}

.details-info-right-content {
    flex: 1 1 auto; // fill all remaining space
    min-width: 400px;
}

.element-row.expanded {
    border-bottom-color: transparent;
    box-shadow:
        0 1px 0px -6px rgba(0, 0, 0, 0.2),
        0 1px 7px -5px rgba(0, 0, 0, 0.14),
        0 2px 7px 0px rgba(0, 0, 0, 0.12) !important;
}

.cell-align-center {
    text-align: center;
    width: 100%;
}