@import "_colors";

.btn-default,
.btn-primary {
    border-radius: 0;
    font-weight: bold;
    font-size: 14px;
}

.btn-default {
    color: $button-color;
    border-color: $button-color;
    padding: 4px;
}

.btn-primary {
    background-color: $button-color;
}
