//------------------------------------------------
// !!! WARNING !!!
// It is common styles for whole application.
// All componentes wil be able to use them.
// Be carefull to add styles here.
//------------------------------------------------

@import "_colors";

.fill-remaining-space {
    flex: 1 1 auto;
}

.baseline {
    align-items: baseline;
}

.flipped {
    -o-transform: scaleX(-1);
    -moz-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    transform: scaleX(-1);
}

.border-buttom {
    border-bottom: 1px solid $border-color;
}

.rounded-border {
    border-radius: 180px;
    border: 1px solid $button-color;
    width: fit-content;
    height: fit-content;
}

.vertical-line {
    border-left: 1px solid $border-color;
    width: fit-content;
    padding: 0;
    margin: 0;
}

.pointer {
    cursor: pointer;
}

.input-group-name {
    padding: 10px 0 0 8px;
    font-weight: bold;
    color: $group-name-color;
}

.gray-group {
    background-color: $input-group-color;
}

.menu-button {
    margin: 0 !important;
    padding: 0 !important;
    min-width: 10px !important;
}

.filter-panel {
    padding: 5px 0 0 0;
    color: $button-color;
}

.nav-caption{
    display: inline-block;
    padding-left: 6px;
}

.warning-color {
    color: $warning-color;
}

.info-color {
    color: $darkgray;
}