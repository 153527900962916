$border-color: lightGray;
$body-color: #fff;

$page-content-color:#f5f5f5;
$item-color: #e7e7e7;

// table
$selected-row-color:#EBF2F7;
$expanded-row-color:#fdfdfd;

// declared by template
$lightgray: #EFEFEF;
$darkgray: darkgray;

$input-group-color: #FBFBFB;
$page-background: #fafafa;
$toogle-lite-color: rgba(0, 156, 217, 0.349);
$warning-color: #f44336;

// by invision
$group-name-color: #0B2D71;
$button-color: #0066B2;
$toogle-color: #009DD9;
$light-gray-color: #6B6D6F;