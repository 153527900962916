.filter-bar {
    padding-top: 0;
    padding-bottom: 20px;
}
.filter-bar .dropdown {
    text-align: left;
    background-color: #fff;
    padding-top:10px;
    padding-bottom:10px;
    border: 1px solid #dbdcdd;
}
.filter-bar .dropdown-menu {
    width: 100%;
    top:0;
    padding-top:50px;
    padding-bottom: 0;
    margin-top:0;
    border: none;
    border-radius: 0;
    z-index:900;
}
.filter-bar .dropdown-backdrop {
    display:none !important; /* fix for drop down on mobile */
}
.filter-bar .row > .col {
    padding: 0;
}
.filter-bar .col-grid-toggle { width: 100px; margin-right: 30px; }
.filter-bar hr.divider {
    margin: 15px 10px;
    height: 5px;
}
.filter-bar hr.divider.bottom {
    margin: 0;
    background-color: #9e9e9e;
    height:4px;
}
.filter-bar .relative-fix {
    position:relative;
    z-index: 901;
}
.filter-bar .dropdown-button {
    width: 100%;
    text-align: left;
    padding-left:12px;
    padding-right:12px;
}
.filter-bar .dropdown-button.display-select {
    text-align: center;
    font-size: 1.8em;
}
.filter-bar .dropdown-button,
.filter-bar .dropdown-button:focus {
    background: none;
    border: none;
    font-weight: 500;
    outline: none;
}
.filter-bar .dropdown-button:focus {    
    outline: thin dotted;
    outline-width: thin;
    outline-style: dotted;
    outline-color: -moz-use-text-color; 
}
.filter-bar .dropdown-menu .icon-list {
    margin-left: 0;  
}
.filter-bar .dropdown-menu .icon-list li {
    margin-bottom: 0.7em;
    font-size: 1.1em;
    line-height: 1.3em;
    padding:0;
}
.filter-bar .dropdown-menu .icon-list li.active { 
    background-color: #DBDCDD;
}
.filter-bar .dropdown-menu .icon-list li.active a:hover,
.filter-bar .dropdown-menu .icon-list li a:hover,
.filter-bar .dropdown-menu .icon-list li a:active {
    background-color: #9e9e9e;
    text-decoration: none;
}
.filter-bar .dropdown-menu .icon-list li a:focus {
    outline-offset: 0;

}
.filter-bar .dropdown-menu .icon-list a {
    color: #000;
    display: block;
    width: 100%;
    padding-top: 7px;
    padding-bottom: 7px;
    padding-left: 53px;
}
.filter-bar .display-select {
    color: #8C8F93;
}
.filter-bar .display-select a  span {
    color: #8C8F93;
}
.filter-bar .display-select a.selected  span {
    color: #000;
}
.filter-bar .display-select a:hover span, 
.filter-bar .display-select a:focus span,
.filter-bar .display-select a:active span {
    text-decoration: none;
    outline: none;
    color: #000;
}
.filter-bar .dropdown-menu .icon-list li:before {
    top: 6px;
    left: 37px;
    font-size: 1.275em; 
}
.filter-bar .dropdown-button .glyphicon-chevron-down { top: 6px; }
.filter-bar .open .dropdown-button .glyphicon-chevron-down:before { content: "\e604"; }

/* Filter Tags */
.filter-tags-container {
    margin-top: -45px;
    padding: 0;
    margin-bottom: 25px;
    text-align: center;
    position:relative;
}
.filter-tags-container .filter-tags { 
    padding: 0; 
    margin-bottom: 0; 
    margin-left:20px; 
    margin-right:20px; 
}
.filter-tags-container .filter-tags .separator { 
    margin-left: 5px;
    font-size: 0.95em;
}
.filter-tags-container .filter-tags li {
    list-style-type: none;
    font-size: 1.1em;
    line-height: 1em;
    padding: 15px 23px 15px 20px;
    margin: 0 4px 12px;
    background-color: #DBDCDD;
    display: inline-block;
    font-weight: 500;
}
.filter-tags-container .filter-tags li a { 
    color: #000;
    opacity: 1;
    position: relative;
    top: 0;
    left: 6px;
}
.filter-tags-container .filter-tags li a:hover,
.filter-tags-container .filter-tags li a:focus { 
    opacity: 0.65; 
    text-decoration: none;
}
.filter-tags-container .filter-tags .close {
    text-shadow: none;
    font-size: 20px;
}
.filter-tags-container .filter-tags li:first-child { 
    margin-left: 0;
}
.filter-tags-container .filter-tags li:last-child { 
    margin-right: 0;
} 
/* Extra Small and below */
@media (max-width: 767px) {
    .filter-bar .col-filter-by { margin-left: auto; }
    .filter-bar .dropdown-menu {
        min-width: 100px;
    }
    .col-filtered-tags { 
        margin-left: 0;
        margin-top: 20px;
    }
    .filter-tags-container .filter-tags li {
        font-size: 0.9em;
        padding-left: 11px;
        padding-right: 14px;
        margin: 0 2px 8px;
    }
    .filter-tags-container .filter-tags li:first-child { 
        margin-left: 2px;
    }
    .filter-tags-container .filter-tags li:last-child { 
        margin-right: 2px;
    } 
    .filter-tags-container .filter-tags .close {
        font-size: 1em;
    }
    tags-container .filter-tags .separator {
        margin-left: 3px;
    }
}